export function Now() {
    return Math.round(Date.now() / 1000);
}

export const DateString = (date: Date, hours = true): string => {
    let valor = `${date.getUTCDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    if (hours) valor += ` ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
    return valor;
};

export const nowRelativeDate = (days: number) => ((d) => new Date(d.setDate(d.getDate() - days)))(new Date());
