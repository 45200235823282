import { Row, Col, Card, Alert } from 'react-bootstrap';
import useFileUploader from './useFileUploader';
import Dropzone from 'react-dropzone';
import { useEffect, useState } from 'react';
import './styles.scss';

export type FileType = File & {
    preview?: string;
    formattedSize?: string;
};
export interface ErrorMessage {
    code: string;
    message: string;
}

type FileUploaderProps = {
    onFileUpload?: (files: FileType[]) => void;
    customFileUpload?: (selected: FileType[], files: FileType[]) => Promise<FileType[]>;
    customName?: (file: FileType) => string;
    showPreview?: boolean;
    maxFiles?: number;
    uploadHided?: boolean;
    label?: string;
    validator?: (file: File, selectedFiles: FileType[]) => null | ErrorMessage;
};

export const FileUploader = ({
    showPreview = true,
    onFileUpload,
    uploadHided,
    maxFiles,
    label,
    validator,
    customFileUpload,
    customName,
}: FileUploaderProps) => {
    const { selectedFiles, handleAcceptedFiles, removeFile } = useFileUploader(showPreview);
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
    const hideUpload = uploadHided && selectedFiles.length > 0 ? true : false;
    useEffect(() => {
        /* istanbul ignore if */
        if (maxFiles && selectedFiles.length > maxFiles) {
            removeFile(selectedFiles[0]);
        } else if (onFileUpload) onFileUpload(selectedFiles);
    }, [selectedFiles]);
    useEffect(() => {
        if (errorMessage !== null) setTimeout(() => setErrorMessage(null), 5000);
    }, [errorMessage]);
    return (
        <>
            {errorMessage && (
                <Alert
                    show={true}
                    dismissible
                    className="mb-2"
                    onClose={() => {
                        setErrorMessage(null);
                    }}
                    variant={'danger'}
                    data-testid="alert-item">
                    <Alert.Heading data-testid="alert-item-title">{errorMessage.code}</Alert.Heading>
                    <p data-testid="alert-item-message">{errorMessage.message}</p>
                </Alert>
            )}
            {!hideUpload && (
                <Dropzone
                    validator={
                        validator
                            ? (file) => {
                                  const error = validator(file, selectedFiles);
                                  if (error) setErrorMessage(error);
                                  return error;
                              }
                            : undefined
                    }
                    maxFiles={maxFiles}
                    onDrop={(acceptedFiles, fileRejections) =>
                        handleAcceptedFiles(acceptedFiles, onFileUpload, customFileUpload)
                    }>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dropzone-custom">
                            <div className="dz-message dz-message-custom needsclick" {...getRootProps()}>
                                <input data-testid="file-upload" {...getInputProps()} />
                                <i className="h3 text-muted dripicons-cloud-upload"></i>
                                <h5>{label || 'Arraste o Arquivo ou clique para fazer o upload.'}</h5>
                            </div>
                        </div>
                    )}
                </Dropzone>
            )}

            {showPreview && selectedFiles.length > 0 && (
                <div className="dropzone-previews mt-3" id="uploadPreviewTemplate">
                    {selectedFiles.map((f, i) => {
                        return (
                            <Card className="mt-1 mb-0 shadow-none border" key={i + '-file'}>
                                <div className="p-2" data-testid="item">
                                    <Row className="align-items-center">
                                        {f.preview && (
                                            <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    className="avatar-sm rounded bg-light"
                                                    alt={f.name}
                                                    src={f.preview}
                                                />
                                            </Col>
                                        )}
                                        {!f.preview && (
                                            <Col className="col-auto">
                                                <div className="avatar-sm">
                                                    <span className="avatar-title bg-primary rounded">
                                                        {f.type.split('/')[0]}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        <Col className="ps-0">
                                            <div className="text-muted fw-bold">
                                                {customName ? customName(f) : f.name}
                                            </div>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                        <Col className="text-end">
                                            <div className="btn btn-link btn-lg text-muted shadow-none">
                                                <i
                                                    data-testid="item-delete"
                                                    className="dripicons-cross"
                                                    onClick={() => removeFile(f)}></i>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}
                </div>
            )}
        </>
    );
};
