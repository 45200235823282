import { VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import { LayoutStateType, LayoutTypesAction } from 'redux/features/layouts/layout-slice';
import { LayoutTypes } from 'appConstants';
export const getLayout = (layout: LayoutTypesAction) => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout) {
        case LayoutTypes.LAYOUT_HORIZONTAL:
            layoutCls = HorizontalLayout;
            break;
        case LayoutTypes.LAYOUT_DETACHED:
            layoutCls = DetachedLayout;
            break;
        case LayoutTypes.LAYOUT_FULL:
            layoutCls = FullLayout;
            break;
        default:
            layoutCls = VerticalLayout;
            break;
    }
    return layoutCls;
};
