import { ActionF, ActionType, ModuleType, StatusType } from 'types';

export const actions: ActionF[] = [
    {
        id: '1',
        action: ActionType.CREATE,
        module: ModuleType.MODELS,
        ownerId: '1',
        objectId: '1',
        status: StatusType.SUCCESS,
        userId: '1',
        createdAt: new Date('11-22-2022'),
        updatedAt: new Date('11-22-2022'),
    },
    {
        id: '3',
        action: ActionType.CREATE,
        module: ModuleType.MODELS,
        ownerId: '3',
        objectId: '3',
        status: StatusType.SUCCESS,
        userId: '3',
        createdAt: new Date('11-22-2022'),
        updatedAt: new Date('11-22-2022'),
    },
    {
        id: '2',
        action: ActionType.CREATE,
        module: ModuleType.MODELS,
        ownerId: '2',
        objectId: '2',
        status: StatusType.SUCCESS,
        userId: '2',
        createdAt: new Date('11-22-2022'),
        updatedAt: new Date('11-22-2022'),
    },
];
