import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from 'redux/store';
import { io, Socket } from 'socket.io-client';
import config from 'config';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { HistoricScadaDto } from 'types/api/scada';
import { baseQueryWithReauth } from './query';

interface Notification {
    status: 'on' | 'off' | 'offline';
    funcionamento: string;
    revisao: string;
    proximo: string;
    estimada: string;
}

interface SensorScada {
    scadaSensorId: string;
    tag: string;
    status: 'on' | 'off' | 'offline';
    hora: string;
}

interface Sensors {
    sensors: SensorScada[];
}

interface PromiseResponse {
    on: () => void;
    off: () => void;
}

interface WebSocketEvent {
    ev: string;
    callback: (data: any) => void;
}

type Sockets = {
    [k: string]: Socket<any, any>;
};

const sockets: Sockets = {};

const getSocket = async (url: string, listeners: WebSocketEvent[], getState: () => any, forceNew = false) => {
    if (forceNew || !Object.keys(sockets).includes(url)) {
        return await new Promise<PromiseResponse>((resolve) => {
            const { access } = (getState() as RootState).Auth;
            const completeUrl = new URL(url);
            const socket = io(url, {
                path: `${completeUrl.pathname}/`,
                reconnection: false,
                extraHeaders: {
                    Authorization: `Bearer ${access}`,
                },
            });
            socket.on('connect_error', async (err) => {
                resolve(await getSocket(url, listeners, getState, true));
            });
            socket.on('connect', () => {
                listeners.forEach(({ ev, callback }) => socket.on(ev, callback));
                resolve({
                    on: () => listeners.forEach(({ ev, callback }) => socket.on(ev, callback)),
                    off: () => {
                        listeners.forEach(({ ev }) => socket.off(ev));
                    },
                });
            });
        });
    } else {
        const socket = sockets[url];
        return {
            on: () => listeners.forEach(({ ev, callback }) => socket.on(ev, callback)),
            off: () => {
                listeners.forEach(({ ev }) => socket.off(ev));
            },
        };
    }
};

export const webSocketApi = createApi({
    reducerPath: 'webSocketApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getNotification: builder.query<HistoricScadaDto | null, HistoricScadaDto['scadaSensorId']>({
            queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
                const { data } = (await baseQuery(`scada-entity/${id}/historic/last`)) as QueryReturnValue<
                    HistoricScadaDto | null,
                    FetchBaseQueryError,
                    {}
                >;
                return { data: data || null };
            },
            async onCacheEntryAdded(id, { cacheDataLoaded, cacheEntryRemoved, updateCachedData, getState }) {
                try {
                    await cacheDataLoaded;
                    const { on, off } = await getSocket(
                        `${config.REACT_APP_API_URL}/scada-data`,
                        [
                            {
                                ev: id,
                                callback: (update: HistoricScadaDto) => {
                                    updateCachedData((_) => update);
                                },
                            },
                        ],
                        getState
                    );
                    on();

                    await cacheEntryRemoved;

                    off();
                } catch {
                    // if cacheEntryRemoved resolved before cacheDataLoaded,
                    // cacheDataLoaded throws
                }
            },
        }),
    }),
});

export const { useGetNotificationQuery } = webSocketApi;
