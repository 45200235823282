import axios, { AxiosError } from 'axios';
import { Buffer } from 'buffer';
import {
    ActivityCreate,
    ActivityDto,
    AssetCreate,
    AssetsDto,
    AtividadeF,
    AtividadeStatusValues,
    UserCreate,
    UserDto,
    SuperUserDto,
    SuperUserCreate,
    SuperUserUpdate,
    WorkflowCreate,
    WorkflowDto,
    Modelo3dDto,
    Modelo3dCreate,
    ForgeAuth,
    ManifestStatus,
    UserCredentials,
    UserAuth,
    UserProfile,
    UsuarioF,
    UserUpdate,
    MessageDto,
    ComentarioF,
    WorkflowUpdate,
    AtivoF,
    SensorDto,
    SensorF,
    SensorUpdate,
    SensorCreate,
    OwnerDto,
    OwnerCreate,
    OwnerForm,
    SuperUsuarioF,
    OwnerFormUpdate,
    UsuarioChangePassF,
    ObjectDto,
    AccObjectDto,
    GeoJsonDto,
    GeoJsonCreate,
    MarkerDto,
    MarkerCreate,
    GeotiffDto,
    GeoTiffCreate,
    ActivityUpdate,
    AtivoModelsPropertiesDto,
    ActionDto,
    DisciplineDto,
    DocumentF,
    CreateDocument,
    UpdateDocument,
    DocumentDto,
    TagsDto,
    CloudCreditDto,
    CloudCreditF,
    CreateCloudCredit,
    UpdateCloudCredit,
} from 'types';
import FormData from 'form-data';
import { SensorDataCreate, SensorDataDto } from 'types/api/sensor-data';

import config from '../config';
import { AtivoStateDto } from 'types/api/status';
import { Limits } from 'components/CustomComponents/FormAtivo';
import { isNumber } from 'lodash';

export const instance = axios.create({
    // Valor de resposta em bytes para representação em MB usar << 20 para KB usar << 10
    maxBodyLength: 20 << 20,
    baseURL: config.REACT_APP_API_URL,
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            // const apicore = new APICore();
            // try {
            //     const refreshToken = apicore.getRefreshFromSession();
            //     if (refreshToken) {
            //         const { access_token, refresh_token } = await Api.refreshToken(refreshToken);
            //         setAuthorization(access_token);
            //         apicore.setRefreshToken(refresh_token);
            //         originalRequest.headers.Authorization = `Bearer ${access_token}`;
            //         return axios(originalRequest);
            //     }
            // } catch (error) {
            //     apicore.setLoggedInUser(null);
            //     apicore.setRefreshToken(null);
            //     setAuthorization(null);
            // }
        }

        return Promise.reject(error);
    }
);

interface ClientId {
    clientId: string;
}

interface Status {
    status: boolean;
}

export interface CreateComment {
    activity: ActivityDto['id'];
    text?: string;
    file?: File;
}
interface ErrorResponse {
    response: {
        data: {
            message?: string;
        };
    };
}
const throwError = ({
    response: {
        data: { message },
    },
}: ErrorResponse) => {
    if (Array.isArray(message)) throw new Error(message.join('\n'));
    throw new Error(message);
};

export class Api {
    // Funções de usuário para chamada a api
    static getUser(id: UserDto['id']) {
        return instance.get<UserDto>(`/users/${id}`).then((result) => result.data);
    }
    static getUsers() {
        return instance.get<UserDto[]>('/users').then((result) => result.data);
    }
    static createUser(user: UserCreate) {
        return instance
            .post<UserDto>('/users', user)
            .then((response) => response.data)
            .catch(throwError);
    }
    static updateUser(id: UserDto['id'], user: UserUpdate) {
        return instance
            .patch<UserDto>(`/users/${id}/update`, user)
            .then((response) => response.data)
            .catch(throwError);
    }
    static removeUser(id: UserDto['id']) {
        return instance
            .delete<UserDto>(`users/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    static resetUserPassword(id: UserDto['id'], password: string, passwordConfirm: string): any {
        return instance.patch(`users/${id}/reset-password`, {
            newPassword: password,
            newPasswordConfirm: passwordConfirm,
        });
    }
    static updateUserPassword({ password, checkPassoword, currentPassword }: UsuarioChangePassF): any {
        return instance.patch('users/update-password', {
            oldPassword: currentPassword,
            newPassword: password,
            newPasswordConfirm: checkPassoword,
        });
    }
    //funções ACC
    static setACCCode(id: OwnerDto['id'], code: string) {
        return instance
            .post(`/forge/${id}/setAccCode`, {
                code,
            })
            .then((result) => result.data);
    }
    static getClientId() {
        return instance.get<ClientId>('/owners/clientId').then((result) => result.data.clientId);
    }
    static accStatus(ownerId: string) {
        return instance.get<Status>(`/forge/${ownerId}/accstatus`).then((result) => result.data.status);
    }
    // Funções de superusuário para chamada a api
    static getSuperUser(id: SuperUserDto['id']) {
        return instance.get<SuperUserDto>(`/superusers/${id}`).then((result) => result.data);
    }
    static getSuperUsers() {
        return instance.get<SuperUserDto[]>('/superusers').then((result) => result.data);
    }
    static createSuperUser(user: SuperUserCreate) {
        return instance
            .post<SuperUserDto>('/superusers', user)
            .then((response) => response.data)
            .catch(throwError);
    }
    static updateSuperUser(id: SuperUserDto['id'], user: SuperUserUpdate) {
        return instance
            .patch<SuperUserDto>(`superusers/${id}/update`, user)
            .then((response) => response.data)
            .catch(throwError);
    }
    static removeSuperUser(id: SuperUserDto['id']) {
        return instance
            .delete<SuperUserDto>(`superusers/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    static resetSuperUserPassword(id: UserDto['id'], password: string, passwordConfirm: string): any {
        return instance.patch(`superusers/${id}/reset-password`, {
            newPassword: password,
            newPasswordConfirm: passwordConfirm,
        });
    }
    static updateSuperUserPassword({ password, checkPassoword, currentPassword }: UsuarioChangePassF): any {
        return instance.patch('superusers/update-password', {
            oldPassword: currentPassword,
            newPassword: password,
            newPasswordConfirm: checkPassoword,
        });
    }
    // Funções de disciplinas para chamada a api
    static async getDisciplines() {
        return instance.get<DisciplineDto[]>('disciplines').then((result) => result.data);
    }

    static async createDiscipline(name: string) {
        return instance
            .post<DisciplineDto>('disciplines', {
                name,
            })
            .then((result) => result.data)
            .catch(throwError);
    }

    // Funções de tags para chamada a api
    static async getTags() {
        return instance.get<TagsDto[]>('tags').then((result) => result.data);
    }

    static async createTag(name: string) {
        return instance
            .post<TagsDto>('tags', {
                name,
            })
            .then((result) => result.data)
            .catch(throwError);
    }

    // Funções de ativos para chamada a api

    static async getAssets() {
        return instance.get<AssetsDto[]>('/Assets').then((result) => result.data);
    }
    static async getAsset(id: AssetsDto['id']) {
        return instance.get<AssetsDto>(`/Assets/${id}`).then((result) => result.data);
    }
    // static async createAsset({ latitude, longitude, ...asset }: AssetCreate) {
    //     const body: any = { ...asset };
    //     if (Number.isFinite(Number(latitude)) && Number.isFinite(Number(longitude))) {
    //         body.latitude = Number(latitude);
    //         body.longitude = Number(longitude);
    //     }
    //     return instance
    //         .post<AssetsDto>('/Assets', body)
    //         .then((result) => result.data)
    //         .catch(throwError);
    // }
    static async removeAsset(id: AssetsDto['id']) {
        return instance
            .delete<AssetsDto>(`/Assets/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    // static async updateAsset(id: AssetsDto['id'], { latitude, longitude, ...asset }: AssetCreate) {
    //     const body: any = { ...asset };
    //     if (Number.isFinite(Number(latitude)) && Number.isFinite(Number(longitude))) {
    //         body.latitude = Number(latitude);
    //         body.longitude = Number(longitude);
    //     }
    //     return instance
    //         .patch<AssetsDto>(`/Assets/${id}/update`, body)
    //         .then((result) => result.data)
    //         .catch(throwError);
    // }
    static async updateAssetLimits(id: AssetsDto['id'], body: Limits) {
        return instance
            .patch<AssetsDto>(`/Assets/${id}/update-limits`, body)
            .then((result) => result.data)
            .catch(throwError);
    }
    //Funções de propriedades de ativo
    static getProperties(id: AtivoF['id']) {
        return instance
            .get<AtivoModelsPropertiesDto[]>(`/assets/${id}/search-name`)
            .then((result) => result.data)
            .catch(throwError);
    }
    static addProperties(id: AtivoF['id'], properties: AtivoModelsPropertiesDto[]) {
        return instance
            .post(`/assets/${id}/search`, properties)
            .then((_) => properties)
            .catch(throwError);
    }
    static updateProperties(id: AtivoF['id'], properties: AtivoModelsPropertiesDto[]) {
        return instance
            .patch(`/assets/${id}/update-search`, properties)
            .then((_) => properties)
            .catch(throwError);
    }
    //Funções de Documentos
    static getDocuments() {
        return instance
            .get<DocumentDto[]>('/documents')
            .then((result) => result.data)
            .catch(throwError);
    }
    static getDocument(id: DocumentF['id']) {
        return instance
            .get<DocumentDto>(`/documents/${id}`)
            .then((result) => result.data)
            .catch(throwError);
    }
    static createDocument({ file, assetId, name, elementName, externalId, model3dId, tagsInIds }: CreateDocument) {
        const form = new FormData();
        form.append('name', name);
        form.append('file', file);
        form.append('assetId', assetId);
        if (elementName) form.append('elementName', elementName);
        if (externalId) form.append('externalId', externalId);
        if (model3dId) form.append('model3dId', model3dId);
        tagsInIds.forEach((tag, i) => {
            form.append(`tagsInIds[${i}]`, tag);
        });
        return instance
            .post<DocumentDto>('/documents', form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }
    static updateDocument(id: DocumentF['id'], update: UpdateDocument) {
        return instance
            .patch<DocumentDto>(`/documents/${id}/update`, update)
            .then((response) => response.data)
            .catch(throwError);
    }
    static removeDocument(id: DocumentF['id']) {
        return instance
            .delete<DocumentDto>(`/documents/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    static getDocumentFile(id: DocumentF['id'], access_token: string) {
        return instance.get<Blob>(`/documents/${id}/download`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            responseType: 'blob',
        });
    }
    //Funções de Status
    static getStatus() {
        return instance.get<AtivoStateDto[]>('/status').then((response) => response.data);
    }

    static getAssetStatus(id: AtivoF['id']) {
        return instance.get<AtivoStateDto>(`/status/${id}`).then((response) => response.data);
    }

    //Funções de Comentarios
    static getCommentFile(id: ComentarioF['id'], access_token: string) {
        return instance.get<Blob>(`/messages/${id}/file`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            responseType: 'blob',
        });
    }
    static getActivityComments(id: ActivityDto['id']) {
        return instance.get<MessageDto[]>(`/messages/byActivity/${id}`).then((response) => response.data);
    }
    static getActivityCommentsWFiles(id: ActivityDto['id']) {
        return instance.get<MessageDto[]>(`/messages/byActivityWithFile/${id}`).then((response) => response.data);
    }
    static createComment({ activity, text, file }: CreateComment) {
        const form = new FormData();
        form.append('activityId', activity);
        if (text) form.append('text', text);
        if (file) form.append('file', file);
        return instance
            .post<MessageDto>('/messages', form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }
    //funçoes de Actions para chamada a api
    static getActionsByObjectId(objectId: string) {
        return instance.get<ActionDto[]>(`actions/${objectId}`).then((result) => result.data);
    }
    //Funções de Atividades para chamada a api
    static getActivities() {
        return instance.get<ActivityDto[]>('activities').then((result) => result.data);
    }
    static getActivity(id: AtividadeF['id']) {
        return instance.get<ActivityDto>(`activities/${id}`).then((result) => result.data);
    }
    static removeAtividadeLink(id: AtividadeF['id']) {
        return instance
            .patch<ActivityDto>(`/activities/${id}/update`, {
                elementName: null,
                externalId: null,
                model3dId: null,
                objectId: null,
                fragId: null,
            })
            .then((response) => response.data)
            .catch(throwError);
    }

    static addAtividadeLink(
        id: AtividadeF['id'],
        externalId: string,
        objectId: number,
        fragId: number,
        elementName: string,
        model3dId: string
    ) {
        return instance
            .patch<ActivityDto>(`/activities/${id}/update`, {
                elementName,
                externalId,
                model3dId,
                objectId,
                fragId,
            })
            .then((response) => response.data)
            .catch(throwError);
    }
    static createAtivity(data: ActivityCreate) {
        return instance
            .post<ActivityDto>('activities', data)
            .then((result) => result.data)
            .catch(throwError);
    }
    static updateAtivity(id: AtividadeF['id'], data: ActivityUpdate) {
        return instance
            .patch<ActivityDto>(`activities/${id}/update`, data)
            .then((result) => result.data)
            .catch(throwError);
    }
    static removeAtivity(id: AtividadeF['id']) {
        return instance
            .delete<ActivityDto>(`/activities/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    // static updateAtivityStatus(id: AtividadeF['id'], status: AtividadeStatusValues, user: UsuarioF['id'] | null) {
    //     return instance
    //         .patch<UpdateStatus>(`activities/${id}/UpdateStatus`, { newStatus: status, newResponsableId: user })
    //         .then((_) => ({ status, id, user }))
    //         .catch(throwError);
    // }
    // Funções de workflow para chamada a api

    static getWorkflow(id: WorkflowDto['id']) {
        return instance.get<WorkflowDto>(`/workflows/${id}`).then((result) => result.data);
    }

    static getWorkflows() {
        return instance.get<WorkflowDto[]>('/workflows').then((result) => result.data);
    }

    static createWorkflow(workflow: WorkflowCreate) {
        return instance
            .post<WorkflowDto>('/workflows', workflow)
            .then((response) => response.data)
            .catch(throwError);
    }

    static updateWorkflow(id: WorkflowDto['id'], workflow: WorkflowUpdate) {
        return instance
            .patch<WorkflowDto>(`workflows/${id}/update`, workflow)
            .then((response) => response.data)
            .catch(throwError);
    }

    static removeWorkflow(id: WorkflowDto['id']) {
        return instance
            .delete<WorkflowDto>(`workflows/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    // Funções de Modelo3D para chamada a api

    static getModel(id: Modelo3dDto['id']) {
        return instance.get<Modelo3dDto>(`/models3d/${id}`).then((result) => result.data);
    }

    static getModels(asset?: string) {
        let url = '/models3d';
        if (asset) url += `?asset=${asset}`;
        return instance.get<Modelo3dDto[]>(url).then((result) => result.data);
    }
    static statusModel(id: Modelo3dDto['id']) {
        return instance.get<ManifestStatus>(`/models3d/${id}/status`).then((result) => result.data);
    }
    static createModel(modelo: Modelo3dCreate) {
        const form = new FormData();
        Object.keys(modelo).forEach((k) => {
            if (modelo[k as keyof typeof modelo] !== undefined) {
                form.append(k, modelo[k as keyof typeof modelo]);
            }
        });
        return instance
            .post<Modelo3dDto>('/models3d', form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }

    static updateModel(id: Modelo3dDto['id'], modelo: Modelo3dCreate) {
        const form = new FormData();
        Object.keys(modelo).forEach((k) => {
            if (modelo[k as keyof typeof modelo] !== undefined) {
                form.append(k, modelo[k as keyof typeof modelo]);
            }
        });
        return instance
            .patch<Modelo3dDto>(`models3d/${id}/update`, form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }

    static removeModel(id: Modelo3dDto['id']) {
        return instance
            .delete<Modelo3dDto>(`models3d/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    // Funções de chamada ap forge
    static forgeToken(access_token: string) {
        return instance
            .get<ForgeAuth>(`/forge/oauth/public`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((result) => result.data.access_token);
    }
    static accforgeToken(ownerId: string, access_token: string) {
        return instance
            .get<ForgeAuth>(`/forge/${ownerId}/accOauth/public`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((result) => result.data.access_token);
    }
    static getModelProperties(urn: string) {
        return instance.get<string[]>(`/forge/${urn}/properties`).then((result) => result.data);
    }
    // Funções de autenticação da api

    static getToken({ email, password, isSuperuser = false }: UserCredentials) {
        let endpoint = 'auth/login';
        if (isSuperuser) endpoint = 'auth/superuser/login';
        return instance
            .post<UserAuth>(endpoint, { email, password })
            .then((response) => response.data)
            .catch(({ message, response }: AxiosError) => {
                if (response && response.status === 401) throw new Error('Usuário e/ou senha inválidos');
                throw new Error(message);
            });
    }
    static refreshToken(refresh: string) {
        return instance
            .post<UserAuth>('auth/refresh', { refresh_token: refresh })
            .then((response) => response.data)
            .catch(({ message, response }: AxiosError) => {
                if (response && response.status === 401) throw new Error('Token expirado');
                throw new Error(message);
            });
    }
    //to be construct
    static userLogout() {
        return;
    }

    static userLogin() {
        return instance.get<UserProfile>('auth/profile').then((response) => response.data);
    }

    // Funções de avatar
    static getImage(id: string, access_token: string) {
        return instance
            .get(`local-files/${id}`, {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => Buffer.from(response.data, 'binary').toString('base64'));
    }
    static setSuperUserAvatar(id: SuperUsuarioF['id'], file: File) {
        const form = new FormData();
        form.append('file', file);
        return instance
            .patch(`superusers/${id}/avatar`, form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }
    static setAvatar(id: UsuarioF['id'], file: File) {
        const form = new FormData();
        form.append('file', file);
        return instance
            .patch(`Users/${id}/avatar`, form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }
    static setAssetAvatar(id: UsuarioF['id'], file: File) {
        const form = new FormData();
        form.append('file', file);
        return instance
            .patch(`assets/${id}/avatar`, form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }
    //Funções de creditos
    static async getCredits() {
        return instance.get<CloudCreditDto[]>('cloud-credits').then((result) => result.data);
    }
    static async createCredit(credit: CreateCloudCredit) {
        return instance
            .post<CloudCreditDto>('cloud-credits', credit)
            .then((response) => response.data)
            .catch(throwError);
    }
    static async updateCredit(creditId: CloudCreditF['id'], { creditConsumedByCall, description }: UpdateCloudCredit) {
        let body: any = {};
        if (description) body.description = description;
        if (creditConsumedByCall) body.creditConsumedByCall = creditConsumedByCall;
        return instance
            .patch<CloudCreditDto>(`cloud-credits/${creditId}`, body)
            .then((response) => response.data)
            .catch(throwError);
    }
    //FUnções de sensores
    static async getSensors() {
        return instance.get<SensorDto[]>('/sensors').then((result) => result.data);
    }
    static getSensorsByAsset(ativoId: AtivoF['id']) {
        return instance.get<SensorDto[]>(`/sensors/asset/${ativoId}`).then((result) => result.data);
    }

    static getSensor(sensorId: SensorF['id']) {
        return instance.get<SensorDto>(`/sensors/${sensorId}`).then((result) => result.data);
    }

    static removeSensorLink(sensorId: SensorF['id']) {
        return instance
            .patch<SensorDto>(`/sensors/${sensorId}`, {
                externalId: null,
                objectId: null,
                fragId: null,
                code: '',
            })
            .then((response) => response.data)
            .catch(throwError);
    }

    static addSensorLink(sensorId: SensorF['id'], externalId: string, objectId: number, fragId: number, code: string) {
        return instance
            .patch<SensorDto>(`/sensors/${sensorId}`, {
                externalId,
                objectId,
                fragId,
                code,
            })
            .then((response) => response.data)
            .catch(throwError);
    }

    static getSensorData(sensorId: SensorF['id'], start_date?: Date, end_date?: Date) {
        let url = `/sensors/${sensorId}/data`;
        if (start_date || end_date) url += '?';
        if (start_date) url += `start_date=${start_date.toISOString()}`;
        if (start_date && end_date) url += '&';
        if (end_date) url += `end_date=${end_date.toISOString()}`;
        return instance
            .get<SensorDataDto[]>(url)
            .then((result) => result.data)
            .catch(throwError);
    }

    static getSensorDataByAsset(AssetId: AtivoF['id'], start_date?: Date, end_date?: Date) {
        let url = `/sensors/asset/${AssetId}/data`;
        if (start_date || end_date) url += '?';
        if (start_date) url += `start_date=${start_date.toISOString()}`;
        if (start_date && end_date) url += '&';
        if (end_date) url += `end_date=${end_date.toISOString()}`;
        return instance
            .get<SensorDataDto[]>(url)
            .then((result) => result.data)
            .catch(throwError);
    }

    static removeSensorData(sensorId: SensorF['id'], start_date?: Date, end_date?: Date) {
        let url = `/sensors/${sensorId}/data`;
        if (start_date || end_date) url += '?';
        if (start_date) url += `start_date=${start_date.toISOString()}`;
        if (start_date && end_date) url += '&';
        if (end_date) url += `end_date=${end_date.toISOString()}`;
        return instance
            .delete<SensorDataDto[]>(url)
            .then((result) => result.data)
            .catch(throwError);
    }
    static createSensor({ unit, heights, ...sensor }: SensorCreate) {
        let body: any = {
            ...sensor,
        };
        if (unit)
            body = {
                unit,
                ...body,
            };
        if (heights && heights.length > 0)
            body = {
                heights,
                ...body,
            };
        return instance
            .post<SensorDto>('/sensors', { ...body })
            .then((response) => response.data)
            .catch(throwError);
    }
    static removeSensor(sensorId: SensorF['id']) {
        return instance
            .delete<SensorDto>(`/sensors/${sensorId}`)
            .then((_) => sensorId)
            .catch(throwError);
    }

    static updateSensor(
        sensorId: SensorF['id'],
        {
            code,
            description,
            name,
            sensorType,
            location,
            data,
            file,
            unit,
            heights,
            limitInfAlertOne,
            limitInfAlertTwo,
            limitInfAlertThree,
            limitInfEmergencyOne,
            limitInfEmergencyTwo,
            limitInfEmergencyThree,
            limitSupAlertOne,
            limitSupAlertTwo,
            limitSupAlertThree,
            limitSupEmergencyOne,
            limitSupEmergencyTwo,
            limitSupEmergencyThree,
        }: SensorUpdate
    ) {
        let body: any = {
            code,
            name,
            description,
            sensorType,
            location,
            data,
            file,
        };
        if (unit)
            body = {
                unit,
                ...body,
            };
        if (heights && heights.length > 0)
            body = {
                heights,
                ...body,
            };
        [
            [
                ['limitInfAlertOne', limitInfAlertOne],
                ['limitInfEmergencyOne', limitInfEmergencyOne],
            ],
            [
                ['limitInfAlertTwo', limitInfAlertTwo],
                ['limitInfEmergencyTwo', limitInfEmergencyTwo],
            ],
            [
                ['limitInfAlertThree', limitInfAlertThree],
                ['limitInfEmergencyThree', limitInfEmergencyThree],
            ],
            [
                ['limitSupAlertOne', limitSupAlertOne],
                ['limitSupEmergencyOne', limitSupEmergencyOne],
            ],
            [
                ['limitSupAlertTwo', limitSupAlertTwo],
                ['limitSupEmergencyTwo', limitSupEmergencyTwo],
            ],
            [
                ['limitSupAlertThree', limitSupAlertThree],
                ['limitSupEmergencyThree', limitSupEmergencyThree],
            ],
        ].forEach(([[k1, v1], [k2, v2]]) => {
            if (isNumber(v1) && isNumber(v2)) {
                body[k1 as string] = v1;
                body[k2 as string] = v2;
            }
        });
        return instance
            .patch<SensorDto>(`/sensors/${sensorId}`, body)
            .then((response) => response.data)
            .catch(throwError);
    }
    static addSensorDataFile(sensorId: SensorF['id'], file: File) {
        const form = new FormData();
        form.append('file', file);
        return instance
            .post(`/sensors/${sensorId}/data-file`, form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }

    static createSensorData(
        sensorId: SensorDto['id'],
        sensorType: SensorDto['sensorType'],
        sensorData: SensorDataCreate[]
    ) {
        return instance
            .post(`/sensors/${sensorId}/${sensorType}`, sensorData)
            .then((response) => response.data)
            .catch(throwError);
    }

    static removeSensorDataById(sensorId: SensorDto['id'], sensorDataId: SensorDataDto['id']) {
        return instance
            .delete(`/sensors/${sensorId}/data/${sensorDataId}`)
            .then((_) => sensorDataId)
            .catch(throwError);
    }

    static removeSensorDataMultiple(sensorId: SensorDto['id'], sensorDataIds: SensorDataDto['id'][]) {
        return instance
            .delete(`/sensors/${sensorId}/data`, { data: { sensorDataIds: sensorDataIds } })
            .then((_) => sensorDataIds)
            .catch(throwError);
    }

    // Funções de Owners
    static getOwner(id: OwnerDto['id']) {
        return instance.get<OwnerDto>(`/owners/${id}`).then((result) => result.data);
    }
    static getOwners() {
        return instance.get<OwnerDto[]>('/owners').then((result) => result.data);
    }
    static createOwner(owner: OwnerCreate) {
        return instance
            .post<OwnerDto>('/owners', owner)
            .then((response) => response.data)
            .catch(throwError);
    }
    static updateOwner(id: OwnerForm['id'], owner: OwnerFormUpdate) {
        return instance
            .patch<OwnerDto>(`owners/${id}/update`, owner)
            .then((response) => response.data)
            .catch(throwError);
    }
    static removeOwner(id: OwnerDto['id']) {
        return instance
            .delete<OwnerDto>(`owners/${id}/delete`)
            .then((_) => id)
            .catch(throwError);
    }
    //funções objects
    static getObjectbyOwner(bucketId: string) {
        return instance
            .get<ObjectDto[]>(`forge/${bucketId}/objects`)
            .then((response) => response.data)
            .catch(throwError);
    }
    static getACCObjectbyOwner(OwnerId: string) {
        return instance
            .get<AccObjectDto[]>(`forge/${OwnerId}/acc/objects`)
            .then((response) => response.data)
            .catch(throwError);
    }
    //MultiPolygons
    static getMultiPolygons(assetId: AssetsDto['id']) {
        return instance
            .get<GeoJsonDto[]>(`gis/multipolygon/${assetId}`)
            .then(({ data }) => data)
            .catch(throwError);
    }
    static createMultiPolygons({ data, ...geo }: GeoJsonCreate) {
        return instance
            .post<GeoJsonDto>(`gis/multipolygon`, { data: { type: 'MultiPolygon', coordinates: data }, ...geo })
            .then(({ data }) => data)
            .catch(throwError);
    }
    static removeMultiPolygon(id: GeoJsonDto['id']) {
        return instance
            .delete<GeoJsonDto>(`gis/multipolygon/${id}`)
            .then((_) => ({ id }))
            .catch(throwError);
    }
    //Marker
    static getMarkers(assetId: AssetsDto['id']) {
        return instance
            .get<MarkerDto[]>(`gis/point/${assetId}`)
            .then(({ data }) => data)
            .catch(throwError);
    }
    static createMarkers({ data, ...marker }: MarkerCreate) {
        return instance
            .post<MarkerDto>(`gis/point`, {
                data: {
                    type: 'Point',
                    coordinates: data,
                },
                ...marker,
            })
            .then(({ data }) => data)
            .catch(throwError);
    }
    static removeMarker(id: MarkerDto['id']) {
        return instance
            .delete<MarkerDto>(`gis/point/${id}`)
            .then((_) => ({ id }))
            .catch(throwError);
    }
    //funções de shapefile
    static saveShapeFile(assetId: AtivoF['id'], title: string, shp: File, dbf: File, prj: File) {
        const form = new FormData();
        form.append('assetId', assetId);
        form.append('title', title);
        form.append('shapeFileShp', shp);
        form.append('shapeFileDbf', dbf);
        form.append('shapeFilePrj', prj);
        return instance
            .post<GeoJsonDto>('gis/shapefile', form, {
                headers: { 'Content-Type': 'multipart/form-data', boundary: 'any' },
            })
            .then((response) => response.data)
            .catch(throwError);
    }
    // static getShapeFiles() {
    //     return instance
    //         .get<GeoJsonDto[]>('gis/shapefile')
    //         .then((result) => result.data)
    //         .catch(throwError);
    // }

    //funções de geotiff
    static getGeotiffs(id: AssetsDto['id']) {
        return instance
            .get<GeotiffDto[]>(`/gis/geotiff/${id}`)
            .then((result) => result.data)
            .catch(throwError);
    }
    static createGeotiffs({ assetId, ...geotiff }: GeoTiffCreate) {
        return instance
            .post<GeotiffDto>(`gis/geotiff/${assetId}`, { assetId, ...geotiff })
            .then(({ data }) => data)
            .catch(throwError);
    }
    static removeGeotiffs(id: GeotiffDto['id']) {
        return instance
            .delete<GeotiffDto>(`gis/geotiff/${id}`)
            .then((_) => ({ id }))
            .catch(throwError);
    }
}
