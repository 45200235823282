import { To, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const useNavigateTimeout = (to: To, ms: number) => {
    const navigate = useNavigate();

    useEffect(() => {
        const time = setTimeout(() => {
            navigate(to);
        }, ms);
        return () => clearTimeout(time);
    }, []);
};
