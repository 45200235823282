import { AcessAction, AcessItem, DragValidation } from 'types/enum/atividades';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import { UserDto, UsuarioFuncao } from 'types';

const defineAbility = (user?: UserDto) => {
    const { can, cannot, build } = new AbilityBuilder(createMongoAbility);
    if (user?.role === UsuarioFuncao.GLOBAL || user?.role === UsuarioFuncao.ADMINISTRADOR) {
        can('manage', 'all');
        cannot(AcessAction.CREATE, AcessItem.ATIVIDADE);
        cannot(DragValidation.ABERTA, AcessItem.ATIVIDADE);
        cannot(DragValidation.EM_EXECUCAO, AcessItem.ATIVIDADE);
        cannot(DragValidation.PARA_AVALIACAO, AcessItem.ATIVIDADE);
        cannot(DragValidation.EM_AVALIACAO, AcessItem.ATIVIDADE);
        cannot(DragValidation.CONCLUIDA, AcessItem.ATIVIDADE);
    } else {
        can(AcessAction.READ, AcessItem.ATIVIDADE);
        can(AcessAction.READ, AcessItem.WORKFLOW);
        can(AcessAction.READ, AcessItem.USUARIO);
        can(AcessAction.READ, AcessItem.ATIVO, { id: { $in: user?.inAssetsIds } });
        can(AcessAction.READ, AcessItem.SENSOR);
        can(AcessAction.CREATE, AcessItem.ATIVIDADE);
        can(AcessAction.UPDATE, AcessItem.ATIVIDADE);
        can(AcessAction.DELETE, AcessItem.ATIVIDADE);
        can(AcessAction.DO, AcessItem.WORKFLOW, { doersIds: { $in: [user?.id] } });
        can(AcessAction.APPROVE, AcessItem.WORKFLOW, { approversIds: { $in: [user?.id] } });
        can(DragValidation.ABERTA, AcessItem.ATIVIDADE, { doersIds: { $in: [user?.id] } });
        can(DragValidation.EM_EXECUCAO, AcessItem.ATIVIDADE, { doersIds: { $in: [user?.id] } });
        can(DragValidation.PARA_AVALIACAO, AcessItem.ATIVIDADE, { doersIds: { $in: [user?.id] } });
        can(DragValidation.PARA_AVALIACAO, AcessItem.ATIVIDADE, { approversIds: { $in: [user?.id] } });
        can(DragValidation.EM_AVALIACAO, AcessItem.ATIVIDADE, { approversIds: { $in: [user?.id] } });
        can(DragValidation.CONCLUIDA, AcessItem.ATIVIDADE, { approversIds: { $in: [user?.id] } });
        can(DragValidation.CONCLUIDA, AcessItem.ATIVIDADE, { doersIds: { $in: [user?.id] } });
    }

    return build();
};

export default defineAbility;
