import { SensorDataDto } from 'types';

export const sensordatas: SensorDataDto[] = [
    {
        id: '1',
        date: new Date().toUTCString(),
        sensorId: '1',
        measurement_x: 15,
        measurement_y: 17,
        measurement_z: 14,
        unit: 'cm',
    },
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '1',
        measurement_x: 18,
        measurement_y: 20,
        measurement_z: 22,
        unit: 'cm',
    },
];

export const sensordatasterm: SensorDataDto[] = [
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '4',
        measurement: 10,
        unit: 'Cº',
    },
];
export const sensordatasinclino: SensorDataDto[] = [
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '4',
        measurement_x: 10,
        measurement_y: 12,
        unit: 'cm',
    },
];
export const sensordatasvazao: SensorDataDto[] = [
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '4',
        measurement: 10,
        unit: 'cm',
    },
];

export const sensordataspiezo: SensorDataDto[] = [
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '4',
        measurement: 10,
        unit: 'cm',
    },
];

export const sensordatasexten: SensorDataDto[] = [
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '4',
        measurement: 10,
        unit: 'cm',
    },
];

export const sensordatastrior: SensorDataDto[] = [
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '4',
        measurement_x: 10,
        measurement_y: 15,
        measurement_z: 13,
        unit: 'cm',
    },
];

export const sensordataspend: SensorDataDto[] = [
    {
        id: '2',
        date: new Date().toUTCString(),
        sensorId: '4',
        measurement_x: 10,
        measurement_y: 17,
        unit: 'cm',
    },
];
