import { AtivoF, BaseF, WorkflowF } from '.';

export const SuperUsuarioFuncao = {
    ADMINISTRADOR: 'admin',
    REGULAR: 'regular',
} as const;

export const UsuarioFuncao = {
    GLOBAL: 'global',
    ADMINISTRADOR: 'admin',
    COMUM: 'regular',
} as const;

type Keys = keyof typeof UsuarioFuncao;
export type FuncaoValues = typeof UsuarioFuncao[Keys];

interface UsuarioBase extends BaseF {
    nameComplete: string;
    email: string;
    role: FuncaoValues;
    position: string;
    ownerId: string;
    inAssets: AtivoF['id'][];
    doerInIds: WorkflowF['id'][];
    approverInIds: WorkflowF['id'][];
    observerInIds: WorkflowF['id'][];
}

export interface UsuarioForm extends UsuarioBase {
    password?: string;
    passwordConfirm?: string;
    avatar?: File;
}

export interface UsuarioF extends UsuarioBase {
    avatar: string;
    isSuperuser: boolean;
}

interface basePassF {
    password: string;
    checkPassoword: string;
}
export interface resetPassF extends basePassF, BaseF {}

export interface UsuarioChangePassF extends basePassF {
    currentPassword: string;
}

export interface ChangePasswordCall extends UsuarioChangePassF {
    successcallback?: () => any;
    errorcallback?: () => any;
}
