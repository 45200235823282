import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { UserDto } from 'types';
interface LockUser {
    email: UserDto['email'];
    name: UserDto['nameComplete'];
    isSuperuser: UserDto['isSuperuser'];
}
export interface AuthState {
    access?: string;
    refresh?: string;
    userLogged: boolean;
    userLocked?: LockUser;
    locked?: boolean;
    user?: UserDto;
}

interface AuthPayload {
    callback?: () => void;
    access: string;
    refresh: string;
}

const AUTH_SESSION_KEY = 'hyper_user';
const REFRESH_SESSION_KEY = 'refresh_user';
const LOCKUSER = 'lock_data';

const getInitialState = (): AuthState => {
    const access = Cookies.get(AUTH_SESSION_KEY);
    const refresh = Cookies.get(REFRESH_SESSION_KEY);
    let lock: LockUser | undefined | string = Cookies.get(LOCKUSER);
    if (lock) {
        lock = JSON.parse(atob(lock)) as LockUser;
    }
    return {
        access,
        refresh,
        userLogged: access !== undefined && refresh !== undefined,
        locked: lock !== undefined,
        userLocked: lock as LockUser | undefined,
    };
};
const initialState: AuthState = getInitialState();

const authSlice = createSlice({
    name: 'AuthSlice',
    initialState,
    reducers: {
        loggedOut(state) {
            state.access = undefined;
            state.refresh = undefined;
            state.userLogged = false;
            state.user = undefined;
            Cookies.remove(AUTH_SESSION_KEY);
            Cookies.remove(REFRESH_SESSION_KEY);
        },
        setLockUser(state, { payload }: PayloadAction<LockUser>) {
            state.access = undefined;
            state.refresh = undefined;
            state.userLogged = false;
            state.locked = true;
            state.userLocked = payload;
            Cookies.set(LOCKUSER, btoa(JSON.stringify(payload)), { expires: 1, secure: true });
            Cookies.remove(AUTH_SESSION_KEY);
            Cookies.remove(REFRESH_SESSION_KEY);
        },
        setUserLogged(state, { payload: { access, refresh, callback } }: PayloadAction<AuthPayload>) {
            if (callback) callback();
            state.access = access;
            state.refresh = refresh;
            state.userLogged = true;
            state.locked = false;
            state.userLocked = undefined;
            Cookies.remove(LOCKUSER);
            Cookies.set(AUTH_SESSION_KEY, access, { expires: 1, secure: true });
            Cookies.set(REFRESH_SESSION_KEY, refresh, { expires: 1, secure: true });
        },
        setProfile(state, { payload }: PayloadAction<UserDto>) {
            state.user = payload;
        },
    },
});

export const { loggedOut, setProfile, setUserLogged, setLockUser } = authSlice.actions;

export default authSlice.reducer;
