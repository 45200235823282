import { SensorCreate, SensorUpdateCall } from 'types';

export const filterLimit = (sensor: SensorCreate): SensorCreate => {
    return sensor;
};

export const filterLimitUpdate = (updateSensor: SensorUpdateCall) => {
    return updateSensor;
};

export const objectToFormData = (form: FormData, object: Object, removeNull = false) => {
    Object.keys(object).forEach((key) => {
        const value = (object as any)[key];
        if (Array.isArray(value)) {
            value.forEach((element, index) => {
                form.append(`${key}[${index}]`, element);
            });
        } else {
            if (value === undefined) {
                if (!removeNull) {
                    var a: any = null;
                    form.append(key, a);
                }
            } else {
                form.append(key, value);
            }
        }
    });
};
