import { BaseF } from 'types';
import { BaseState } from '../types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getCommentFile, getFile } from './thunks';
import { saveListOne } from '../utils/actions';

export interface FileReference extends BaseF {
    file: Blob;
}

export interface FilesState extends BaseState {
    files: FileReference[];
    open: boolean;
}

const initialState: FilesState = {
    loading: true,
    open: false,
    files: [],
};

const fileSlice = createSlice({
    name: 'FilesSlice',
    initialState,
    reducers: {
        changemodal(state, action: PayloadAction<boolean>) {
            state.open = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFile.fulfilled, (state, { payload }) => {
                saveListOne<FilesState, FileReference>(state, 'files', payload);
            })
            .addCase(getFile.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getFile.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCommentFile.fulfilled, (state, { payload }) => {
                saveListOne<FilesState, FileReference>(state, 'files', payload);
            })
            .addCase(getCommentFile.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getCommentFile.pending, (state) => {
                state.loading = true;
            });
    },
});
export const { changemodal } = fileSlice.actions;
export default fileSlice.reducer;
