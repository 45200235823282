import { Alert } from 'react-bootstrap';
import { AlertaVariant } from 'types';
import { useToggle } from 'hooks';

interface Props {
    title: string;
    message: string;
    variant: AlertaVariant;
}
export function AlertMessage(props: Props) {
    const [show, setShow] = useToggle(true);
    const style: React.CSSProperties = {};
    if (show) style['background'] = 'white';
    return (
        <div style={style} className="mb-1" data-testid="alert-div">
            <Alert
                show={show}
                dismissible
                className="mb-0"
                onClose={setShow}
                variant={props.variant}
                data-testid="alert-item">
                <Alert.Heading data-testid="alert-item-title">{props.title}</Alert.Heading>
                <p data-testid="alert-item-message">{props.message}</p>
            </Alert>
        </div>
    );
}
