import { LayoutColor, LayoutTypes, LayoutWidth, SideBarTheme, SideBarWidth } from 'appConstants';

const hash = '8023dc05db8ebb03f4c9bd69e25705d0';

export const defaultPref = {
    layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
    layoutType: LayoutTypes.LAYOUT_VERTICAL,
    layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_DARK,
    leftSideBarType: SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED,
    showRightSidebar: false,
} as const;

export function getPreferences<T>(): T {
    let preferences = { ...defaultPref };
    const cookie = document.cookie
        .split(';')
        .map((value) => value.split('='))
        .filter(([k]) => k.trim() === hash)
        .map(([k, v]) => JSON.parse(v));
    if (cookie.length > 0) preferences = cookie[0];
    setPreferences<T>(preferences as unknown as T);
    return preferences as unknown as T;
}
export function setPreferences<T>(prefs: T) {
    document.cookie = `${hash}=${JSON.stringify(prefs)};max-age=${86400 * 365};path=/;`;
}
