import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Capitalize } from 'utils';

export type BreadcrumbItems = {
    label?: string;
    path: string;
    active?: boolean;
};

type PageTitleProps = {
    breadCrumbItems: Array<BreadcrumbItems | null>;
    title: string;
};

const PageTitle = ({ breadCrumbItems, title }: PageTitleProps) => {
    const navigate = useNavigate();
    return (
        <Row>
            <Col>
                <div data-testid="page-title" className="page-title-box">
                    <div className="page-title-right">
                        <Breadcrumb listProps={{ className: 'm-0' }}>
                            <Breadcrumb.Item data-testid={'page-title-home'} onClick={() => navigate('/')}>
                                Home
                            </Breadcrumb.Item>

                            {breadCrumbItems
                                .filter((item) => item !== null)
                                .map((item, index) => {
                                    return (
                                        <Breadcrumb.Item
                                            data-testid={`page-title-crumb-${item!.label}${
                                                item!.active ? '-active' : ''
                                            }`}
                                            active={item!.active || false}
                                            key={index.toString()}
                                            onClick={() => {
                                                if (!item!.active) navigate(item!.path);
                                            }}>
                                            {item && item.label ? Capitalize(item.label) : null}
                                        </Breadcrumb.Item>
                                    );
                                })}
                        </Breadcrumb>
                    </div>
                    <h4 className="page-title">{title}</h4>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
