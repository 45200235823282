import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeOne, saveListOne } from '../utils/actions';
import { AtivoF, SensorDataF, SensorF, SensorDataCreate } from 'types';
import { nowRelativeDate } from 'utils/time';
import { convertData } from './utils';
import { Api } from 'service/api';
import { alert } from '../utils/alert';
import { resetState } from '../utils/reset-state';

interface PropsData {
    start_date?: Date;
    end_date?: Date;
}
interface SensorInfo {
    sensorId: SensorF['id'];
}

interface getSensorDataProps extends PropsData, SensorInfo {}

interface createSensorDataProps {
    sensorId: SensorF['id'];
    sensorType: SensorF['sensorType'];
    sensorData: SensorDataCreate[];
}

interface getSensorDataByAssetProps extends PropsData {
    assetId: AtivoF['id'];
}

interface addSensorDataFileProps extends SensorInfo {
    update?: boolean;
    file: File;
}

// export const getSensorData = createAsyncThunk(
//     'SensorSlice/getSensorData',
//     async ({ sensorId, start_date, end_date }: getSensorDataProps) => {
//         return { data: await Api.getSensorData(sensorId, start_date, end_date), sensorId };
//     }
// );

// export const createSensorData = createAsyncThunk(
//     'SensorSlice/createSensorData',
//     async ({ sensorId, sensorType, sensorData }: createSensorDataProps, { dispatch }) => {
//         return {
//             data: await Api.createSensorData(sensorId, sensorType, sensorData).then((r) => {
//                 dispatch(getSensorData({ sensorId }));
//                 return r;
//             }),
//         };
//     }
// );
interface removeProps {
    sensorId: SensorF['id'];
    sensorDataId: SensorDataF['id'];
}

interface removePropsMultiple {
    sensorId: SensorF['id'];
    sensorDataIds: SensorDataF['id'][];
}
// export const removeSensorData = createAsyncThunk(
//     'SensorSlice/removeSensorData',
//     async ({ sensorId, sensorDataId }: removeProps, { dispatch }) =>
//         await Api.removeSensorDataById(sensorId, sensorDataId).catch(alert(dispatch, 'Falha ao remover SensorData'))
// );
// export const removeSensorDataMultiple = createAsyncThunk(
//     'SensorSlice/removeSensorDataMultiple',
//     async ({ sensorId, sensorDataIds }: removePropsMultiple, { dispatch }) =>
//         await Api.removeSensorDataMultiple(sensorId, sensorDataIds).catch(alert(dispatch, 'Falha ao remover SensorData'))
// );
// export const addSensorDataFile = createAsyncThunk(
//     'SensorSlice/addSensorDataFile',
//     async ({ sensorId, file, update = false }: addSensorDataFileProps, { dispatch }) => {
//         return await Api.addSensorDataFile(sensorId, file)
//             .then((response) => {
//                 if (update) dispatch(getSensorData({ sensorId }));
//                 return response;
//             })
//             .catch(alert(dispatch, 'Falha ao atualizar Ativo'));
//     }
// );

// export const getSensorDataByAsset = createAsyncThunk(
//     'SensorSlice/getSensorDataByAsset',
//     async ({ assetId, start_date, end_date }: getSensorDataByAssetProps) => {
//         return { data: await Api.getSensorDataByAsset(assetId, start_date, end_date), assetId };
//     }
// );

interface SearchDates {
    start_date: Date;
    end_date: Date | null;
}

export enum DateFilterType {
    CUSTOM = 'custom',
    LAST_MONTH = 'month',
    LAST_WEEK = 'week',
    LAST_DAY = 'day',
}

export interface SensorDataState extends SearchDates {
    filtro: DateFilterType;
}

const initialState: SensorDataState = {
    start_date: nowRelativeDate(1),
    filtro: DateFilterType.LAST_DAY,
    end_date: null,
};

const SensorDataSlice = createSlice({
    name: 'SensorDataSlice',
    initialState,
    reducers: {
        reset: (state) => resetState(state, initialState),
        setStartDate(state, { payload }: PayloadAction<Date>) {
            if (state.start_date !== payload) state.start_date = payload;
        },
        setEndDate(state, { payload }: PayloadAction<Date | null>) {
            if (state.end_date !== payload) state.end_date = payload;
        },
        setDates(state, { payload: { start_date, end_date } }: PayloadAction<SearchDates>) {
            if (state.start_date !== start_date) state.start_date = start_date;
            if (state.end_date !== end_date) state.end_date = end_date;
        },
        setFiltro(state, { payload }: PayloadAction<DateFilterType>) {
            if (state.filtro !== payload) state.filtro = payload;
        },
    },
    // extraReducers(builder) {
    //     builder
    //         .addCase(getSensorData.pending, (state) => {
    //             state.loading = true;
    //         })
    //         .addCase(getSensorDataByAsset.pending, (state) => {
    //             state.loading = true;
    //         })
    //         .addCase(getSensorData.fulfilled, (state, { payload: { data, sensorId } }) => {
    //             data.forEach((value) => saveListOne(state, 'sensoresData', convertData(value)));
    //             state.lastCalled = sensorId;
    //             state.loading = false;
    //         })
    //         .addCase(getSensorDataByAsset.fulfilled, (state, { payload: { assetId, data } }) => {
    //             data.forEach((value) => saveListOne(state, 'sensoresData', convertData(value)));
    //             state.lastCalled = assetId;
    //             state.loading = false;
    //         })
    //         .addCase(removeSensorData.fulfilled, (state, { payload }) => removeOne(state, 'sensoresData', payload))
    //         .addCase(removeSensorDataMultiple.fulfilled, (state, { payload }) => {
    //             payload.forEach(id => removeOne(state, 'sensoresData', id))
    //         })
    // },
});
export const { setDates, setStartDate, setEndDate, setFiltro, reset } = SensorDataSlice.actions;
export default SensorDataSlice.reducer;
