import { resetState } from '../utils/reset-state';
import { createSlice } from '@reduxjs/toolkit';

interface DeleteState {
    open: boolean;
}

const initialState: DeleteState = {
    open: false,
};

const DeleteSlice = createSlice({
    name: 'DeleteSlice',
    initialState,
    reducers: {
        reset: (state) => resetState(state, initialState),
        changemodal(state) {
            state.open = !state.open;
        },
    },
});

export const { changemodal, reset } = DeleteSlice.actions;
export default DeleteSlice.reducer;
