import { Modelo3DCreateCall, Modelo3dDto, AssetsDto } from 'types';
/* istanbul ignore file */

export const modelos: Modelo3dDto[] = [
    {
        id: '1',
        title: 'titulo1',
        description: 'description',
        asset: { id: '1' } as AssetsDto,
        isSensorModel: true,
        location: 'someplace',
        disciplineId: '1',
        datafile: 'myfile.txt',
        urn: '1',
        status: 'success',
    },
    {
        id: '2',
        title: 'titulo2',
        description: 'description',
        asset: { id: '1' } as AssetsDto,
        isSensorModel: true,
        location: 'someplace',
        disciplineId: '1',
        datafile: 'myfile.txt',
        urn: '2',
        status: 'success',
    },
    {
        id: '3',
        title: 'titulo3',
        description: 'description',
        asset: { id: '1' } as AssetsDto,
        isSensorModel: true,
        location: 'someplace',
        disciplineId: '1',
        datafile: 'myfile.txt',
        urn: '3',
        status: 'success',
    },
    {
        id: '4',
        title: 'titulo4',
        description: 'description',
        asset: { id: '1' } as AssetsDto,
        isSensorModel: false,
        location: 'someplace',
        disciplineId: '1',
        datafile: 'myfile.txt',
        urn: '4',
        status: 'success',
    },
    {
        id: '5',
        title: 'titulo5',
        description: 'description',
        asset: { id: '2' } as AssetsDto,
        isSensorModel: true,
        location: 'someplace',
        disciplineId: '1',
        datafile: 'myfile.txt',
        urn: '5',
        status: 'success',
    },
];

export const models3d: Modelo3dDto[] = [
    {
        id: '1',
        title: 'Bombina',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin porttitor in ex sit amet dapibus.',
        asset: { id: '2' } as AssetsDto,
        disciplineId: '1',
        isSensorModel: true,
        location: 'Barreira RJ',
        datafile: 'myfile.txt',
        urn: '',
        status: 'success',
    },
    {
        id: '2',
        title: 'Planta baixa',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin porttitor in ex sit amet dapibus.',
        asset: { id: '1' } as AssetsDto,
        disciplineId: '3',
        isSensorModel: false,
        location: 'Barreira Minas',
        datafile: 'myfile.txt',
        urn: '',
        status: 'success',
    },
    {
        id: '3',
        title: 'Arduino',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin porttitor in ex sit amet dapibus.',
        asset: { id: '2' } as AssetsDto,
        disciplineId: '1',
        isSensorModel: true,
        location: 'Barreira SC',
        datafile: 'myfile.txt',
        urn: '',
        status: 'success',
    },
    {
        id: '4',
        title: 'Planta sala de máquinas',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin porttitor in ex sit amet dapibus.',
        asset: { id: '2' } as AssetsDto,
        disciplineId: '2',
        isSensorModel: false,
        location: 'Barreira São Paulo',
        datafile: 'myfile.txt',
        urn: '',
        status: 'success',
    },
];

export const modelocreate: Modelo3DCreateCall = {
    id: '1',
    title: 'titulo',
    description: 'description',
    isSensorModel: false,
    assetId: '1',
    location: 'someplace',
    disciplineId: '1',
    datafile: new File(['myfile'], 'myfile.txt'),
};
