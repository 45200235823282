import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

type DatepickerInputProps = {
    testid: string;
    onClick?: () => void;
    value?: string;
    inputClass: string;
    disabled?: boolean;
    children?: React.ReactNode;
};

/* Datepicker with Input */
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
    const onDateValueChange = () => {
        return;
    };
    return (
        <input
            type="text"
            data-testid={props.testid}
            className="form-control date bla"
            onClick={props.onClick}
            value={props.value}
            onChange={(props as any).onChange}
            disabled={props.disabled}
            ref={ref}
        />
    );
});

/* Datepicker with Addon Input */
const DatepickerInputWithAddon = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => (
    <div className="input-group" ref={ref}>
        <input
            type="text"
            data-testid={props.testid}
            className="form-control form-control-light"
            onClick={props.onClick}
            value={props.value}
            disabled={props.disabled}
            readOnly
        />
        <div className="input-group-append">
            <span className="input-group-text bg-primary border-primary text-white">
                <i className="mdi mdi-calendar-range font-13"></i>
            </span>
        </div>
    </div>
));

type HyperDatepickerProps = {
    id: string;
    value: Date;
    onChange: (date: Date) => void;
    hideAddon?: boolean;
    inputClass?: string;
    dateFormat?: string;
    minDate?: Date;
    maxDate?: Date;
    className?: string;
    showTimeSelect?: boolean;
    tI?: number;
    timeFormat?: string;
    timeCaption?: string;
    showTimeSelectOnly?: boolean;
    monthsShown?: number;
    inline?: boolean;
    disabled?: boolean;
};

const HyperDatepicker = (props: HyperDatepickerProps) => {
    // handle custom input
    const input =
        (props.hideAddon || false) === true ? (
            <DatepickerInput
                testid={props.id}
                disabled={props.disabled ? props.disabled : false}
                inputClass={props.inputClass!}
            />
        ) : (
            <DatepickerInputWithAddon
                testid={props.id}
                disabled={props.disabled ? props.disabled : false}
                inputClass={props.inputClass!}
            />
        );

    return (
        <>
            {/* date picker control */}
            <DatePicker
                data-testid="datepicker"
                disabled={props.disabled ? props.disabled : false}
                customInput={input}
                timeIntervals={props.tI}
                className={classNames('form-control', props.inputClass)}
                selected={props.value}
                onChange={(date) => props.onChange(date!)}
                showTimeSelect={props.showTimeSelect}
                timeFormat={props.timeFormat || 'hh:mm a'}
                timeCaption={props.timeCaption}
                dateFormat={props.dateFormat || 'MM/dd/yyyy'}
                minDate={props.minDate}
                maxDate={props.maxDate}
                monthsShown={props.monthsShown}
                showTimeSelectOnly={props.showTimeSelectOnly}
                inline={props.inline}
                showYearDropdown
                showMonthDropdown
                autoComplete="off"
            />
        </>
    );
};

export default HyperDatepicker;
