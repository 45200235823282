import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutColor, LayoutTypes, LayoutWidth, SideBarTheme, SideBarWidth } from 'appConstants';
import { getPreferences, setPreferences } from 'layouts/util';

export type LayoutTypesAction =
    | LayoutTypes.LAYOUT_VERTICAL
    | LayoutTypes.LAYOUT_HORIZONTAL
    | LayoutTypes.LAYOUT_DETACHED
    | LayoutTypes.LAYOUT_FULL;
type LayoutColorAction = LayoutColor.LAYOUT_COLOR_LIGHT | LayoutColor.LAYOUT_COLOR_DARK;
type LayoutWidthAction = LayoutWidth.LAYOUT_WIDTH_FLUID | LayoutWidth.LAYOUT_WIDTH_BOXED;
type SideBarThemeAction =
    | SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT
    | SideBarTheme.LEFT_SIDEBAR_THEME_DARK
    | SideBarTheme.LEFT_SIDEBAR_THEME_DEFAULT;
type SideBarWidthAction =
    | SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED
    | SideBarWidth.LEFT_SIDEBAR_TYPE_CONDENSED
    | SideBarWidth.LEFT_SIDEBAR_TYPE_SCROLLABLE;
export type LayoutStateType = {
    layoutColor: LayoutColorAction;
    layoutType: LayoutTypesAction;
    layoutWidth: LayoutWidthAction;
    leftSideBarTheme: SideBarThemeAction;
    leftSideBarType: SideBarWidthAction;
    showRightSidebar: boolean;
    onClick?: () => void;
};
function setCookie({
    layoutColor,
    layoutType,
    layoutWidth,
    leftSideBarTheme,
    leftSideBarType,
    showRightSidebar,
}: LayoutStateType) {
    setPreferences<LayoutStateType>({
        layoutColor,
        layoutType,
        layoutWidth,
        leftSideBarTheme,
        leftSideBarType,
        showRightSidebar,
    });
}
const layoutSlice = createSlice({
    name: 'LayoutSlice',
    initialState: getPreferences<LayoutStateType>(),
    reducers: {
        setOnclick(state, { payload }: PayloadAction<LayoutStateType['onClick']>) {
            state.onClick = payload;
        },
        callOnclick(state) {
            if (state.onClick) state.onClick();
        },
        changeLayout(state, { payload }: PayloadAction<LayoutTypesAction>) {
            state.layoutType = payload;
            setCookie(state);
        },
        changeLayoutColor(state, { payload }: PayloadAction<LayoutColorAction>) {
            state.layoutColor = payload;
            setCookie(state);
        },
        changeLayoutWidth(state, { payload }: PayloadAction<LayoutWidthAction>) {
            state.layoutWidth = payload;
            setCookie(state);
        },
        changeSidebarTheme(state, { payload }: PayloadAction<SideBarThemeAction>) {
            state.leftSideBarTheme = payload;
            setCookie(state);
        },
        changeSidebarType(state, { payload }: PayloadAction<SideBarWidthAction>) {
            state.leftSideBarType = payload;
            setCookie(state);
        },
        showRightSidebar(state) {
            state.showRightSidebar = true;
            /* istanbul ignore else */
            if (document.body) document.body.classList.add('end-bar-enabled');
            setCookie(state);
        },
        hideRightSidebar(state) {
            state.showRightSidebar = false;
            /* istanbul ignore else */
            if (document.body) document.body.classList.remove('end-bar-enabled');
            setCookie(state);
        },
    },
});
export const {
    callOnclick,
    setOnclick,
    changeLayout,
    changeLayoutColor,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    hideRightSidebar,
    showRightSidebar,
} = layoutSlice.actions;
export default layoutSlice.reducer;

export type LayoutActionType<TPayload> = {
    type:
        | typeof changeLayout.type
        | typeof changeLayoutColor.type
        | typeof changeLayoutWidth.type
        | typeof changeSidebarTheme.type
        | typeof changeSidebarType.type
        | typeof showRightSidebar.type
        | typeof hideRightSidebar.type;
    payload?: TPayload;
};
