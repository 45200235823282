export type OutPut = 'Normal' | 'Anormal' | 'Emergencial' | undefined;
export const CalcStateNumber = (state?: number): OutPut => {
    if (state !== undefined) {
        if (state >= 0 && state <= 0.5) {
            return 'Normal';
        } else if (state > 0.5 && state <= 0.7) {
            return 'Anormal';
        } else if (state > 0.7 && state <= 1) {
            return 'Emergencial';
        }
    }
    return undefined;
};
