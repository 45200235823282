import { BaseF } from ".";

export const FileExtension = {
    IMAGE: 0,
    VIDEO: 1,
    DOC: 2,
    AUDIO: 3
} as const;

type Keys = keyof typeof FileExtension;
export type FFileExtensionValues = typeof FileExtension[Keys];

export interface AnexoF extends BaseF {
    nome: string;
    tamanho: number;
    url: string;
    extension: FFileExtensionValues
}