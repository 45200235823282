import { BaseQueryFn, FetchArgs, FetchBaseQueryError, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useAppDispatch } from '../../redux/hooks';
import { useToggle } from '../../hooks';
import { useState } from 'react';
interface Out<T> {
    detail: (item: T) => void;
    edit: (item: T) => void;
    remove: (item: T) => void;
    create: () => void;
    editStatus: boolean;
    item: T | null;
    deleteShow: boolean;
    deleteShowToggle: () => any;
    formShow: boolean;
    formShowToggle: () => any;
    submit: <T>(item: T | undefined) => any;
    cancel: () => any;
}
export function useFormActions<T>(
    createItem: MutationTrigger<
        MutationDefinition<any, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, any, any, 'serviceApi'>
    >,
    updateItem: MutationTrigger<
        MutationDefinition<any, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, any, any, 'serviceApi'>
    >
): Out<T> {
    const dispatch = useAppDispatch();
    const [deleteShow, deleteShowToggle] = useToggle();
    const [formShow, formShowToggle] = useToggle();
    const [editStatus, setEditStatus] = useState(false);
    const [item, setItem] = useState<T | null>(null);
    const detail = (item: T) => {
        setItem(item);
        setEditStatus(false);
        formShowToggle();
    };
    const edit = (item: T) => {
        setItem(item);
        setEditStatus(true);
        formShowToggle();
    };
    const remove = (item: T) => {
        setItem(item);
        deleteShowToggle();
    };
    const create = () => {
        setItem(null);
        setEditStatus(true);
        formShowToggle();
    };
    const submit = <N>(changer?: N) => {
        if (item) {
            updateItem(changer!);
        } else {
            createItem(changer!);
        }
        formShowToggle();
    };
    const cancel = () => formShowToggle();
    return {
        detail,
        edit,
        remove,
        create,
        editStatus,
        item,
        deleteShow,
        deleteShowToggle,
        formShow,
        formShowToggle,
        submit,
        cancel,
    };
}
