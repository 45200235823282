import { DocumentDto } from 'types';

export const mockDocumentos: DocumentDto[] = [
    {
        id: '1',
        name: 'teste',
        assetId: '1',
        createAt: new Date().toUTCString(),
        updateAt: new Date().toUTCString(),
        userId: '1',
        elementName: null,
        externalId: '1',
        file: {
            id: '1',
            originalFileName: 'a.txt',
            mimeType: 'pdf',
            fileSize: 100,
        },
        tagsInIds: [],
        model3dId: '1',
        ownerId: '1',
    },
    {
        id: '2',
        name: 'teste',
        assetId: '1',
        createAt: new Date().toUTCString(),
        updateAt: new Date().toUTCString(),
        userId: '1',
        elementName: 'name',
        externalId: '1',
        file: {
            id: '2',

            fileSize: 100,
            originalFileName: 'a.txt',
            mimeType: 'pdf',
        },
        tagsInIds: [],
        model3dId: '1',
        ownerId: '1',
    },
    {
        id: '3',
        name: 'teste',
        assetId: '1',
        createAt: new Date().toUTCString(),
        updateAt: new Date().toUTCString(),
        userId: '1',
        elementName: 'name',
        externalId: '1',
        file: {
            id: '3',
            fileSize: 100,
            originalFileName: 'a.txt',
            mimeType: 'pdf',
        },
        tagsInIds: [],
        model3dId: '1',
        ownerId: '1',
    },
    {
        id: '4',
        name: 'teste',
        assetId: '1',
        createAt: new Date().toUTCString(),
        updateAt: new Date().toUTCString(),
        userId: '1',
        elementName: 'name',
        externalId: '1',
        file: {
            id: '4',
            fileSize: 100,
            originalFileName: 'a.txt',
            mimeType: 'pdf',
        },
        tagsInIds: [],
        model3dId: '1',
        ownerId: '1',
    },
    {
        id: '5',
        name: 'teste',
        assetId: '1',
        createAt: new Date().toUTCString(),
        updateAt: new Date().toUTCString(),
        userId: '1',
        elementName: 'name',
        externalId: '1',
        file: {
            id: '5',
            fileSize: 100,
            originalFileName: 'a.txt',
            mimeType: 'pdf',
        },
        tagsInIds: [],
        model3dId: '1',
        ownerId: '1',
    },
    {
        id: '6',
        name: 'teste',
        assetId: '1',
        createAt: new Date().toUTCString(),
        updateAt: new Date().toUTCString(),
        userId: '1',
        elementName: 'name',
        externalId: '1',
        file: {
            id: '6',
            fileSize: 100,
            originalFileName: 'a.txt',
            mimeType: 'pdf',
        },
        tagsInIds: [],
        model3dId: '1',
        ownerId: '1',
    },
    {
        id: '7',
        name: 'teste',
        assetId: '1',
        createAt: new Date().toUTCString(),
        updateAt: new Date().toUTCString(),
        userId: '1',
        elementName: null,
        externalId: '1',
        file: {
            id: '7',
            fileSize: 100,
            originalFileName: 'a.txt',
            mimeType: 'pdf',
        },
        tagsInIds: [],
        model3dId: null,
        ownerId: '1',
    },
];
