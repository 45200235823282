import { BaseF } from './base';

export enum CloudCreditType {
    ModelDerivativeSimple = 'model-derivative-simple',
    ModelDerivativeComplex = 'model-derivative-complex',
    DesignAutomation = 'design-automation',
    RealityDerivative = 'reality-derivative',
}

export interface CreateCloudCredit {
    description: string;
    cloudCreditType: CloudCreditType;
    creditConsumedByCall: number;
}

export interface UpdateCloudCredit {
    description?: string;
    creditConsumedByCall?: number;
}

export interface CloudCreditF extends BaseF, CreateCloudCredit {}
