import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertaF } from 'types';
interface AlertaState {
    alertas: AlertaF[];
}
const initialState: AlertaState = {
    alertas: [],
};

const AlertaSlice = createSlice({
    name: 'AlertaSlice',
    initialState,
    reducers: {
        create(state, action: PayloadAction<AlertaF>) {
            state.alertas.push(action.payload);
        },
        remove(state, action: PayloadAction<AlertaF['id']>) {
            state.alertas = state.alertas.filter((a) => a.id !== action.payload);
        },
    },
});

export const { create, remove } = AlertaSlice.actions;
export default AlertaSlice.reducer;
