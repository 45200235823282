export enum AcessItem {
    ATIVIDADE = 'Atividade',
    DISCIPLINA = 'Disciplina',
    WORKFLOW = 'Workflow',
    USUARIO = 'Usuario',
    SUPERUSUARIO = 'SuperUsuario',
    SENSOR = 'Sensor',
    ATIVO = 'Ativo',
    DOCUMENT = 'Document',
    MODEL = 'Model',
    FORGE = 'forge',
    SCADA = 'scada',
    GIS = 'Gis',
    OWNER = 'Owner',
}

export enum AcessAction {
    APPROVE = 'approve',
    UPDATE = 'update',
    DELETE = 'delete',
    CREATE = 'create',
    READ = 'read',
    DO = 'do',
}
export enum DragValidation {
    PARA_AVALIACAO = 'PARA_AVALIAÇÃO',
    EM_AVALIACAO = 'EM_AVALIAÇÃO',
    EM_EXECUCAO = 'EM_EXECUÇÃO',
    CONCLUIDA = 'CONCLUIDA',
    ABERTA = 'ABERTA',
}
