import { useCreditsQuery, useProfileQuery } from 'redux/features/api/api-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { setProfile } from 'redux/features/auth/auth-slice';
import { CloudCreditType, UserDto } from 'types';
import { useEffect, useRef } from 'react';
import { Spinner } from 'components';

type PrivateRouteProps = {
    component: React.ComponentType;
    isSuper?: boolean;
    secondCheck?: (user: UserDto) => boolean;
    roles?: UserDto['role'] | UserDto['role'][];
};

export const GenericPrivateRoute = ({
    component: RouteComponent,
    isSuper,
    secondCheck,
    roles,
    ...rest
}: PrivateRouteProps) => {
    let location = useLocation();
    const navigate = useNavigate();
    const {
        Auth: { user },
        Layout: { layoutColor },
        DeleteModal: { open },
        Files: { open: fopen },
    } = useAppSelector((state) => state);

    const cannotrefresh = useRef(open || fopen);
    const dispatch = useAppDispatch();
    const { userLogged } = useAppSelector((state) => state.Auth);
    const { data: credits, isSuccess: creditSuccess } = useCreditsQuery(undefined, {
        skip: !user || !user.isSuperuser,
    });

    useEffect(() => {
        cannotrefresh.current = open || fopen;
    }, [fopen, open]);

    useEffect(() => {
        if (user && ((secondCheck && !secondCheck(user)) || (roles && roles.indexOf(user.role) === -1))) navigate('/');
    }, [user, secondCheck]);

    useEffect(() => {
        if (user?.isSuperuser && creditSuccess && credits && credits.length < Object.keys(CloudCreditType).length)
            navigate('/cloud-credits');
    }, [user, credits]);

    useEffect(() => {
        if (!userLogged) {
            navigate('/account/login', { state: { from: location }, replace: true });
        }
    }, []);
    useProfileQuery(undefined, { skip: !userLogged });
    // const api = new APICore();
    // useEffect(() => {
    //     if (isSuccess && data !== undefined) dispatch(setProfile(data));
    // }, [isSuccess]);
    return user ? (
        <RouteComponent />
    ) : (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100vh', background: layoutColor }}>
            <Spinner size="lg" />
        </div>
    );
};
