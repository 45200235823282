import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'redux/store';
import config from 'config';
import { loggedOut, setUserLogged } from '../auth/auth-slice';
import { create, remove } from '../alertas/alertas-slice';
import { v4 as uuidv4 } from 'uuid';
import { NovoAlertaF } from 'types';

export interface LoginResponse {
    access_token: string;
    refresh_token: string;
}

export interface LoginRequest {
    email: string;
    password: string;
}

const baseQuery = fetchBaseQuery({
    baseUrl: config.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).Auth.access;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401 && !(extraOptions as any).auth) {
        const { data } = await baseQuery(
            {
                url: '/auth/refresh',
                method: 'POST',
                body: {
                    refresh_token: (api.getState() as RootState).Auth.refresh,
                },
            },
            api,
            extraOptions
        );
        if (data) {
            const { access_token, refresh_token } = data as LoginResponse;
            api.dispatch(setUserLogged({ access: access_token, refresh: refresh_token }));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(loggedOut());
        }
    } else if (
        result.error &&
        !(extraOptions as any).errorIgnore &&
        (result.error.status === 400 ||
            result.error.status === 500 ||
            result.error.status === 404 ||
            result.error.status === 422)
    ) {
        const alert = (alert: NovoAlertaF, time = 5000) => {
            const id = uuidv4();
            api.dispatch(
                create({
                    ...alert,
                    id,
                })
            );
            if (time > 0) {
                setTimeout(() => {
                    api.dispatch(remove(id));
                }, time);
            }
        };
        alert(
            {
                titulo: `Erro ao ${(extraOptions as any).label || ''}`,
                texto: (result.error.data as any).message || 'Erro Desconhecido Contate o Administrador!',
                variant: 'danger',
            },
            10000
        );
    } else if (result.error && result.error.status === 502) {
        const id = uuidv4();
        api.dispatch(
            create({
                id,
                texto: 'Não foi possivel a comunicação com o servidor, contate um Administrador.',
                titulo: 'Falha em comunicação com servidor!',
                variant: 'danger',
            })
        );
        setTimeout(() => {
            api.dispatch(remove(id));
        }, 5000);
    }
    return result;
};
