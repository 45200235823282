import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Api } from 'service/api';

export const getFile = createAsyncThunk('fileSlice/getFile', async (id: string, { getState }) => {
    const {
        Auth: { access },
    } = getState() as RootState;
    return { id, file: (await Api.getDocumentFile(id, access!)).data };
});

export const getCommentFile = createAsyncThunk('fileSlice/getCommentFile', async (id: string, { getState }) => {
    const {
        Auth: { access },
    } = getState() as RootState;
    return { id, file: (await Api.getCommentFile(id, access!)).data };
});
