import { createSlice } from '@reduxjs/toolkit';
import { BaseState } from '../types';
import { getClientId } from './thunks';

export interface ACCSlice extends BaseState {
    clientId?: string;
}

const initialState: ACCSlice = {
    loading: true,
    clientId: undefined,
};

const ACCSlice = createSlice({
    name: 'ACCSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClientId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getClientId.rejected, (state) => {
                state.loading = false;
                state.clientId = undefined;
            })
            .addCase(getClientId.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.clientId = payload;
            });
    },
});

export default ACCSlice.reducer;
