import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetState } from '../utils/reset-state';
import { ForgeActivityModal, ForgeProperties, ForgeSensorModal, ForgeTooltip, ForgeFullScreen } from './types';

export interface ForgeState
    extends ForgeProperties,
        ForgeSensorModal,
        ForgeActivityModal,
        ForgeTooltip,
        ForgeFullScreen {}

const initialState: ForgeState = {
    showModal: false,
    showTooltip: false,
    showTaskModal: false,
    fullscreen: false,
    properties: [],
    externalIds: [],
};

const ForgeSlice = createSlice({
    name: 'ForgeSlice',
    initialState,
    reducers: {
        reset: (state) => resetState(state, initialState),
        setProperties(state, { payload }: PayloadAction<ForgeProperties['properties']>) {
            state.properties = payload;
        },
        setFullScreen(state, { payload }: PayloadAction<ForgeFullScreen['fullscreen']>) {
            if (payload !== state.fullscreen) {
                state.fullscreen = payload;
            }
        },
        setExternalIds(state, { payload }: PayloadAction<ForgeProperties['externalIds']>) {
            state.externalIds = payload;
        },
        callSensorForm(
            state,
            {
                payload: { urn, showModal, code, formSensor, fragId, objectId, externalId, objectName, position },
            }: PayloadAction<ForgeSensorModal>
        ) {
            state.objectName = objectName;
            state.showTaskModal = false;
            state.urn = urn;
            state.showModal = showModal;
            state.code = code;
            state.formSensor = formSensor;
            state.objectId = objectId;
            state.fragId = fragId;
            state.externalId = externalId;
            state.position = position;
        },
        callTaskForm(
            state,
            {
                payload: {
                    urn,
                    showTaskModal,
                    formTask,
                    fragId,
                    objectId,
                    objectName,
                    externalId,
                    position,
                    edit = true,
                },
            }: PayloadAction<ForgeActivityModal>
        ) {
            state.showModal = false;
            state.objectName = objectName;
            state.edit = edit;
            state.urn = urn;
            state.showTaskModal = showTaskModal;
            state.formTask = formTask;
            state.objectId = objectId;
            state.fragId = fragId;
            state.externalId = externalId;
            state.position = position;
        },
        callTooltip(state, { payload: { showTooltip, sensor, atividade } }: PayloadAction<ForgeTooltip>) {
            state.showTooltip = showTooltip;
            state.atividade = atividade;
            state.sensor = sensor;
        },
    },
});
export const { callSensorForm, callTooltip, reset, callTaskForm, setProperties, setExternalIds, setFullScreen } =
    ForgeSlice.actions;
export default ForgeSlice.reducer;
